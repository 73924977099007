
import { defineComponent, reactive, ref, UnwrapRef, h } from 'vue'
import { mapState, mapActions } from 'vuex'
import { EnvelopeService } from '@/services/EnvelopeService'
import { CountrieService } from '@/services/CountrieService'
import { ValidateService } from '@/services/ValidateService'
import { AccountService } from '@/services/AccountService'
import { PaperSignatureService } from '@/services/PaperSignatureService'
import { FolderService } from '@/services/FolderService'
import {
  PlusOutlined,
  DeleteOutlined,
  HolderOutlined,
  InboxOutlined,
  PaperClipOutlined,
  FileOutlined,
  BarsOutlined,
  UploadOutlined,
  FileTextOutlined,
  OrderedListOutlined,
  EyeOutlined,
  EditOutlined,
  ContactsOutlined
} from '@ant-design/icons-vue'
import 'flag-icons/css/flag-icons.min.css'
import upload from 'ant-design-vue/lib/upload'
import { notification, Modal } from 'ant-design-vue'
import draggable from 'vuedraggable'
import FolderSelectView from '@/views/home/folder/selectCofreNewDocument.vue'
import Schedules from '@/views/schedule/index.vue'

interface RequestRecipients {
  Email: string
  PartName: string
  key: number
  Order: number
  PaperSignatureId: number
  isWhatsapp: boolean
  Phone: string
  PhoneCode: string
  PhoneMask: string
  PhoneFlag: string
  isSaveShared: boolean
  isDocCPF: boolean
  isDocRG: boolean
  isDocOther: boolean
  docMask: string
  Document: string
  DocumentType: string
  iWantToSign: boolean
  isPaperSign: boolean
  isScheduleRecipient: boolean
  fromSchedule: boolean
}

interface Folder {
  folderId: number | null
  folderName: string | null
}

interface FormEnvelope {
  title: string | null
  files: any[]
  folder: Folder
  requests: RequestRecipients[]
  IsOrder : boolean
}

export default defineComponent({
  name: 'NewDocument',
  components: {
    PlusOutlined,
    HolderOutlined,
    InboxOutlined,
    DeleteOutlined,
    PaperClipOutlined,
    FileOutlined,
    BarsOutlined,
    UploadOutlined,
    FileTextOutlined,
    OrderedListOutlined,
    EyeOutlined,
    EditOutlined,
    ContactsOutlined,
    draggable,
    FolderSelectView,
    Schedules
  },
  watch: {
    folderId () {
      this.formEnvelope.folder.folderId = this.folderId
    },
    folderName () {
      this.formEnvelope.folder.folderName = this.folderName
    }
  },
  computed: {
    ...mapState('sections', ['folderId', 'folderName']),
    fileUrl () : string | null {
      return this.formEnvelope.files[0] ? URL.createObjectURL(this.files[0]) : null
    }
  },
  data: () => ({
    rules: {
      Email: [
        {
          type: 'email',
          message: 'Digite um email válido',
          trigger: 'change'
        }
      ]
    },
    openSchedule: ref<boolean>(false),
    accountService: ref<AccountService>(),
    envelopeService: ref<EnvelopeService>(),
    paperSignatureService: ref<PaperSignatureService>(),
    formEnvelope: reactive<FormEnvelope>({
      title: null,
      files: [],
      folder: {
        folderId: null,
        folderName: null
      } as Folder,
      requests: [
        {
          Email: '',
          PartName: '',
          key: Date.now(),
          Order: 1,
          PaperSignatureId: 1,
          isWhatsapp: false,
          Phone: '',
          PhoneCode: '+55',
          PhoneMask: '(##) # ####-####',
          PhoneFlag: 'fi fi-br',
          isDocCPF: true,
          isSaveShared: true,
          isDocRG: false,
          isDocOther: false,
          docMask: '###.###.###-##',
          Document: '',
          DocumentType: 'CPF',
          iWantToSign: false,
          isPaperSign: true,
          isScheduleRecipient: false,
          fromSchedule: false
        }
      ],
      IsOrder: false
    }) as UnwrapRef<FormEnvelope>,
    file: ref<any>(null),
    files: ref<any>([]),
    tempFiles: reactive(new Array(
      {
        files: [] as any,
        envelopeFiles: [] as any
      }
    )),
    loading: ref<boolean>(false),
    visible: ref<boolean>(false),
    formRef: ref(),
    title: ref<string>(''),
    activeTab: ref('1'),
    activeTabViewer: ref('1'),
    isOrderChecked: ref<number>(0),
    isWhatsapp: ref<boolean>(false),
    countriesWhatsapp: ref<any>(null),
    isViewer: ref<boolean>(false),
    isMobile: ref<boolean>(false),
    isForm: ref<boolean>(true),
    isIWantToSign: ref<boolean>(false),
    isZoomed: ref<boolean>(false),
    accountData: ref<any>(null),
    userData: ref<any>(null),
    inUseWhatsapp: ref<number>(0),
    accountLimitWhatsapp: ref<number>(0),
    isWhatsappActive: ref<boolean>(true),
    changeTypeFieldSelected: ref<number>(0),
    inactiveIWantToSign: ref<boolean>(false),
    titleFileSplit: ref<any>([]),
    typeChannel: ref('E-mail'),
    typeDoc: ref('CPF'),
    ops: ref<boolean>(false),
    papers: ref<any>([]),
    isValidCpf: ref<boolean>(false),
    cofreSelected: ref<number>(0)
  }),
  async mounted () {
    if (this.folderId !== 0) {
      this.cofreSelected = this.folderId
      this.formEnvelope.folder.folderId = this.folderId
      this.formEnvelope.folder.folderName = this.folderName
    } else {
      const folders = await FolderService.getInstance().getSelectView()
      this.formEnvelope.folder.folderId = folders.childrens[0].id
      this.formEnvelope.folder.folderName = folders.childrens[0].name
    }

    this.envelopeService = EnvelopeService.getInstance()

    const countrieService = CountrieService.getInstance()
    this.countriesWhatsapp = countrieService.getCountries()

    this.accountService = AccountService.getInstance()
    this.accountData = await this.accountService.GetAccountData()
    this.userData = await this.accountService.GetUserData()
    const getLimitWhatsappAccount = await this.accountService.GetLimitWhatsappAccount(this.accountData.id)

    this.accountLimitWhatsapp = getLimitWhatsappAccount.whatsappLimit
    this.inUseWhatsapp = getLimitWhatsappAccount.whatsappConsummation

    this.paperSignatureService = PaperSignatureService.getInstance()
    await this.getPapersSignatures()

    if (window.innerWidth < 990) {
      this.isMobile = true
      this.isForm = true
      this.isViewer = false
    } else {
      this.isMobile = false
      this.isForm = true
      this.isViewer = true
    }

    this.checkZoom()
    window.addEventListener('resize', this.checkZoom, false)
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.checkZoom, false)
  },
  methods: {
    ...mapActions('sections', [
      'setDrawerMenu',
      'setFolderId',
      'setFolderName'
    ]),
    async getPapersSignatures () {
      this.papers = await this.paperSignatureService?.listPapersSignatureRequest()
      this.moveLastToSecond(this.papers)
    },
    moveLastToSecond (array: any[]) {
      const lastElement = array.pop()
      array.splice(1, 0, lastElement)
    },
    getIndexForItem (item: any) {
      return this.tempFiles.indexOf(item)
    },
    getActualFileIndex (index = '') : number {
      const option = (index !== '') ? parseInt(index) - 1 : 0
      return option
    },
    fileUrlMult (index = '') : string | null {
      const cIndex = this.getActualFileIndex(index)
      return URL.createObjectURL(this.files[cIndex as unknown as number])
    },
    getFileName (index = '') {
      const cIndex = this.getActualFileIndex(index)
      const cFileName = this.files[cIndex as unknown as number].name
      const tempFileName = cFileName.substring(0, cFileName.length - 4)
      let splitFileName = ''
      if (tempFileName.length > 40) {
        splitFileName = tempFileName.substring(0, 40)
      } else {
        splitFileName = tempFileName
      }
      return splitFileName
    },
    showModalInfo () {
      const consummationWhats = this.accountLimitWhatsapp - this.inUseWhatsapp
      const msgOne = 'Agora é possível enviar documentos para assinatura via WhatsApp.'
      const msgTwo = 'Você ainda tem ' + consummationWhats + ' envios bônus conforme o seu pacote contratado.'
      const Three = 'Após a finalização do pacote bônus, cada envio para número Whatsapp terá uma tarifação de R$ 0,49 de acordo com a política de preços da Empresa Meta (Whatsapp).'
      const msgFour = 'Os envios por e-mail seguem sem tarifação.'

      Modal.info({
        title: 'Importante',
        content: h('div', { }, [
          h('p', msgOne),
          h('p', msgTwo),
          h('p', Three),
          h('p', msgFour)
        ]),
        centered: true
      })
    },
    customRequest ({ onSuccess, onError, file }: any) {
      setTimeout(() => {
        onSuccess(null, file)
      }, 100)
    },
    async upload () {
      this.loading = true
      let validFilledFields = false
      const validToSign = this.formEnvelope.requests.filter(
        (x) => (
        ((x.isWhatsapp === false && x.Email === '') ||
        (x.isWhatsapp === true && x.Phone === '')) ||
        ((x.isPaperSign === true && x.Document === '')) ||
        x.PartName === '') &&
        x.iWantToSign === false
      )

      const consumptionWhatsapp = this.changeCountInUseWhatsapp()
     if (consumptionWhatsapp > this.accountLimitWhatsapp && this.accountLimitWhatsapp !== -1) {
        const exceeded = (this.accountLimitWhatsapp - consumptionWhatsapp) * (-1)
        const msg = 'Você excedeu  ' + exceeded + ' notificações de whatsapp. Altere o canal para e-mail ou contrate outro plano!'
        this.loading = false
        notification.error({
          message: 'Limite Excedido',
          description: msg
        })
        return false
      }

      if (validToSign.length > 0) {
        if (validToSign.length === 1) {
            const checkIsFilled = this.formEnvelope.requests.filter(
                (x) => (
                ((x.isWhatsapp === false && x.Email === '') ||
                (x.isWhatsapp === true && x.Phone === '')) &&
                ((x.isPaperSign === true && x.Document === '')) &&
                x.PartName === '') &&
                x.iWantToSign === false
            )

            if (checkIsFilled.length === 0) {
                validFilledFields = true
            } else {
                validFilledFields = false
            }
        } else {
          validFilledFields = false
        }
      } else {
        validFilledFields = false
      }

      if (validFilledFields) {
        notification.error({
          message: 'Falha no upload do arquivo',
          description: 'Os Campos: Nome, Documento e forma de envio são obrigatórios e precisam ser preenchidos.'
        })
        this.loading = false
      } else {
        const checkCpf = this.formEnvelope.requests.filter(
          (x) => this.validateCPF(x.Document, x.isDocCPF, false) === false && x.iWantToSign
        ).length

        if (checkCpf === 0) {
          this.loading = true
          try {
            let requestsToSign = this.formEnvelope.requests.filter(
              (x) => ((x.Email !== '' || x.Phone !== '') && x.PartName !== '' &&
              (x.Document !== '' || !x.isPaperSign)) || x.iWantToSign === true)

            if (requestsToSign.length > 0) {
              const tempRequests = [] as any
              requestsToSign.forEach(el => {
                const countValue = tempRequests.filter((x: any) => (x.Email === el.Email && el.Email !== '') || (x.Phone === el.Phone && el.Phone !== '')).length
                if (countValue === 0) {
                  tempRequests.push(el)
                }
              })

              if (tempRequests.length < requestsToSign.length) {
                notification.error({
                  message: 'Falha no upload do arquivo',
                  description: 'Os Campos: E-mail ou Whatsapp não podem ser repetidos.'
                })
                this.loading = false
              } else {
                this.loading = true

                if (this.isOrderChecked) {
                  requestsToSign = this.sortRequests(requestsToSign)
                }

                const requests = requestsToSign.map(request => ({
                  Email: request.isWhatsapp ? '' : request.Email,
                  Order: request.Order,
                  PaperSignatureId: request.PaperSignatureId,
                  PartName: this.capitalizeText(request.PartName),
                  Phone: request.Phone !== '' ? request.PhoneCode + ' ' + request.Phone : '',
                  key: request.key,
                  Document: request.Document,
                  DocumentType: request.DocumentType,
                  IsSchedule: request.isScheduleRecipient
                }))

                const formData = new FormData()

                if (this.files.length > 1) {
                  this.files.forEach((file: any) => {
                    formData.append('Files', file)
                  })
                } else {
                  formData.append('File', this.files[0])
                }

                formData.append('FolderId', `${this.formEnvelope.folder.folderId}`)
                formData.append('RequestSignature', JSON.stringify(requests))
                formData.append('Title', this.formEnvelope.title!)
                formData.append('IsOrder', this.isOrderChecked ? JSON.stringify(true) : JSON.stringify(false))

                await this.envelopeService?.createEnvelope(formData)

                notification.success({
                  message: 'Upload de arquivo',
                  description: 'O upload do arquivo foi realizado com sucesso'
                })

                this.loading = false
                this.back()
              }
            } else {
              notification.error({
                message: 'Falha no upload do arquivo',
                description: 'Os Campos: Nome, Documento e forma de envio são obrigatórios e precisam ser preenchidos.'
              })
              this.loading = false
            }
          } catch (err) {
            this.loading = false
          }
        } else {
          notification.error({
            message: 'Falha no upload do arquivo',
            description: 'Existe CPF cadastrado inválido.'
          })

          this.loading = false
        }
      }
    },
    resetForm () {
      this.formRef.value.resetFields()
      this.formEnvelope = reactive<FormEnvelope>({
        title: null,
        files: [],
        folder: {
          folderId: null,
          folderName: null
        } as Folder,
        requests: [
          {
            Email: '',
            PartName: '',
            key: Date.now(),
            Order: 1,
            PaperSignatureId: 1,
            isWhatsapp: false,
            Phone: '',
            PhoneCode: '+55',
            PhoneMask: '(##) # ####-####',
            PhoneFlag: 'fi fi-br',
            isDocCPF: true,
            isSaveShared: true,
            isDocRG: false,
            isDocOther: false,
            docMask: '###.###.###-##',
            Document: '',
            DocumentType: 'CPF',
            iWantToSign: false,
            isPaperSign: true,
            isScheduleRecipient: false,
            fromSchedule: false
          }
        ],
        IsOrder: false
      })
      this.file = null
    },
    handleChange (info: any) {
      const fileList = [...info.fileList]
      const extFile = info.file.name.substring(info.file.name.length - 4, info.file.name.length)

      if (extFile.toLowerCase() !== '.pdf') {
        const cIndex = this.getActualFileIndex(this.files.length)
        this.files.forEach((item: any, index: any, object: any) => {
          if (index === cIndex) {
            object.splice(index, 1)
          }
        })
      } else {
        this.formEnvelope.files.push(fileList)
        const status = info.file.status
        this.activeTabViewer = this.formEnvelope.files.length.toString()

        const cIndex = this.getActualFileIndex(this.files.length)
        if (cIndex === 0) {
          const tempName = this.files[0].name.substring(0, this.files[0].name.length - 4)
          this.formEnvelope.title = tempName
        }
      }
    },
    handleChangeCofre (cofreId: any, infoCofre: any) {
      this.cofreSelected = cofreId
      if (cofreId !== this.formEnvelope.folder.folderId) {
        this.formEnvelope.folder.folderId = infoCofre.id
        this.formEnvelope.folder.folderName = infoCofre.name
        this.setFolderId(parseInt(infoCofre.id))
        this.setFolderName(infoCofre.name)
      }
    },
    async handleOk () {
      this.loading = true
    },
    showModalSchedule () {
      this.openSchedule = true
    },
    closeModalSchedule () {
      this.openSchedule = false
    },
    back () {
      this.setDrawerMenu(false)
      this.$router.push({
        name: 'home'
      })
    },
    removeRecipients (item: RequestRecipients) {
      const activeIWantToSign = item.iWantToSign ?? false
      const index = this.formEnvelope.requests.indexOf(item)
      let isObserverOrder = 0
      if (index !== -1) {
        this.formEnvelope.requests.splice(index, 1)
        if (activeIWantToSign) {
          this.isIWantToSign = false
          this.inactiveIWantToSign = false
        }
      }
      this.formEnvelope.requests.forEach((el, i) => {
        if (el.PaperSignatureId === 8) {
          isObserverOrder = i
          el.Order = 0
        } else {
          if (isObserverOrder !== 0) {
            el.Order = isObserverOrder
          } else {
            el.Order = (i + 1)
          }
          isObserverOrder = 0
        }
      })

      this.changeCountInUseWhatsapp()
    },
    addRecipients () {
      let isOrder = false

      if (this.isOrderChecked) isOrder = true

      const countFieldsFiled = this.formEnvelope.requests.filter(
        (x) => ((x.Email !== '' || x.Phone !== '') &&
          (x.Phone.trim() !== '' || x.PartName.trim() !== '') &&
          x.PartName !== '' &&
          ((x.Document !== '' && x.Document.trim() !== '') || !x.isPaperSign)) ||
          x.iWantToSign === true
      ).length

      const checkCpf = this.formEnvelope.requests.filter(
        (x) => this.validateCPF(x.Document, x.isDocCPF, false) === false
      ).length

      if (countFieldsFiled === this.formEnvelope.requests.length && checkCpf === 0) {
        this.formEnvelope.requests.push({
          Email: '',
          PartName: '',
          key: Date.now(),
          Order: this.formEnvelope.requests.length + 1,
          PaperSignatureId: 1,
          isWhatsapp: false,
          Phone: '',
          PhoneCode: '+55',
          PhoneMask: '(##) # ####-####',
          PhoneFlag: 'fi fi-br',
          isDocCPF: true,
          isSaveShared: true,
          isDocRG: false,
          isDocOther: false,
          docMask: '###.###.###-##',
          Document: '',
          DocumentType: 'CPF',
          iWantToSign: false,
          isPaperSign: true,
          isScheduleRecipient: false,
          fromSchedule: false
        })
      } else {
        if (checkCpf > 0) {
          notification.warning({
            message: 'Cpf inválido',
            description: 'O CPF não foi informado ou é inválido, favor corrigir.'
          })
        } else {
          notification.warning({
            message: 'Falha ao adicionar signatário',
            description: 'Para adicionar um novo signatário todos os campos: Nome, Documento e forma de envio anteriores precisam ser preenchidos.'
          })
        }
      }

      this.changeCountInUseWhatsapp()
    },
    validateCPF (cpf: string, isCpf: boolean, isShowMessage: boolean) {
      if (cpf.length === 14 && isCpf) {
        this.isValidCpf = ValidateService.isValidCpf(cpf)

        if (!this.isValidCpf) {
          if (isShowMessage) {
            notification.warning({
              message: 'Cpf inválido',
              description: 'O CPF inserido é inválido, favor corrigir.'
            })
          }

          return false
        }
      } else if (cpf.length > 14 && isCpf) {
        if (isShowMessage) {
          notification.warning({
            message: 'Cpf inválido',
            description: 'O CPF inserido possui caracteres há mais, favor corrigir.'
          })
        }

        return false
      } else if (cpf.length < 14 && isCpf) {
        return false
      }
    },
    checkCpf (strCpf: string) {
      let sum: number
      let rest: number
      sum = 0

      if (strCpf.length !== 11 ||
          strCpf === '00000000000' ||
          strCpf === '11111111111' ||
          strCpf === '22222222222' ||
          strCpf === '33333333333' ||
          strCpf === '44444444444' ||
          strCpf === '55555555555' ||
          strCpf === '66666666666' ||
          strCpf === '77777777777' ||
          strCpf === '88888888888' ||
          strCpf === '99999999999') return false

      for (let i = 1; i <= 9; i++) {
        sum = sum + parseInt(strCpf.substring(i - 1, i)) * (11 - i)
      }
      rest = (sum * 10) % 11

      if ((rest === 10) || (rest === 11)) rest = 0
      if (rest !== parseInt(strCpf.substring(9, 10))) return false

      sum = 0

      for (let i = 1; i <= 10; i++) sum = sum + parseInt(strCpf.substring(i - 1, i)) * (12 - i)
      rest = (sum * 10) % 11

      if ((rest === 10) || (rest === 11)) rest = 0
      if (rest !== parseInt(strCpf.substring(10, 11))) return false

      return true
    },
    changeCountInUseWhatsapp () {
      let inUseWhatsapp = 0
      this.isWhatsappActive = true

      const toSignWhatsapp = this.formEnvelope.requests.filter(
        x => (x.Phone !== '' && x.PartName !== '') || x.isWhatsapp).length

      inUseWhatsapp = (toSignWhatsapp + this.inUseWhatsapp)

      if (inUseWhatsapp >= this.accountLimitWhatsapp && this.accountLimitWhatsapp !== -1) {
       this.isWhatsappActive = false
      }

      return inUseWhatsapp
    },
    showModalOrderFiles () {
      this.tempFiles = []
      this.files.forEach((el: any, i: number) => {
        this.tempFiles.push({
            files: el,
            envelopeFiles: this.formEnvelope.files[i]
        })
      })
      this.visible = !this.visible
    },
    closeModalOrderFiles () {
      this.visible = false
    },
    removeItemInOrderFiles (item: any) {
      const index = this.tempFiles.indexOf(item)
      if (index !== -1) {
        this.tempFiles.splice(index, 1)
      }
    },
    confirmOrderFiles () {
      this.formEnvelope.files = []
      this.files = []
      this.tempFiles.forEach(info => {
        this.files.push(info.files)
        this.formEnvelope.files.push(info.envelopeFiles)
      })
      this.visible = false
      this.activeTabViewer = this.formEnvelope.files.length.toString()
    },
    sortRequests (requests: any) {
      const noSign = requests.filter((x: any) => x.isPaperSign === false)
      const sign = requests.filter((x: any) => x.isPaperSign === true)
      const sort = [] as any
      sign.forEach((y: any) => {
        y.Order = sort.length + 1
        sort.push(y)
      })
      noSign.forEach((y: any) => {
        y.Order = 0
        sort.push(y)
      })
      return sort
    },
    dragDropInRecipients () {
      this.formEnvelope.requests = this.sortRequests(this.formEnvelope.requests)
    },
    changeTypeChannel (item: RequestRecipients) {
      this.changeCountInUseWhatsapp()
      const index = this.formEnvelope.requests.indexOf(item)
      if (index !== -1) {
        this.formEnvelope.requests[index].Email = ''
        this.formEnvelope.requests[index].Phone = ''
        this.formEnvelope.requests[index].PhoneCode = '+55'
        this.formEnvelope.requests[index].PhoneMask = '(##) # ####-####'
        this.formEnvelope.requests[index].PhoneFlag = 'fi fi-br'
      }
    },
    changeTypeDoc (item: RequestRecipients) {
      const index = this.formEnvelope.requests.indexOf(item)
      if (index !== -1) {
        const docType = this.formEnvelope.requests[index].DocumentType
        if (docType === 'CPF') {
          this.formEnvelope.requests[index].isDocCPF = true
          this.formEnvelope.requests[index].isSaveShared = true
          this.formEnvelope.requests[index].isDocRG = false
          this.formEnvelope.requests[index].isDocOther = false
          this.formEnvelope.requests[index].docMask = '###.###.###-##'
          this.formEnvelope.requests[index].Document = ''
        }
        if (docType === 'RG') {
          this.formEnvelope.requests[index].isDocCPF = false
          this.formEnvelope.requests[index].isSaveShared = true
          this.formEnvelope.requests[index].isDocRG = true
          this.formEnvelope.requests[index].isDocOther = false
          this.formEnvelope.requests[index].docMask = ''
          this.formEnvelope.requests[index].Document = ''
        }
        if (docType === 'OTHER') {
          this.formEnvelope.requests[index].isDocCPF = false
          this.formEnvelope.requests[index].isSaveShared = true
          this.formEnvelope.requests[index].isDocRG = false
          this.formEnvelope.requests[index].isDocOther = true
          this.formEnvelope.requests[index].docMask = ''
          this.formEnvelope.requests[index].Document = ''
        }
      }
    },
    changeCountryWhatsRecipients (item: RequestRecipients) {
      const country = this.countriesWhatsapp.filter((v: any) => v.ddi === item.PhoneCode)
      if (country.length > 0) {
        const index = this.formEnvelope.requests.indexOf(item)
        if (index !== -1) {
          this.formEnvelope.requests[index].Phone = ''
          this.formEnvelope.requests[index].PhoneMask = country[0].mask
          this.formEnvelope.requests[index].PhoneCode = item.PhoneCode
          this.formEnvelope.requests[index].PhoneFlag = country[0].flag
        }
      }
    },
    changePaper (item: RequestRecipients) {
      const index = this.formEnvelope.requests.indexOf(item)
      if (index !== -1) {
        const checkPaper = this.formEnvelope.requests[index].PaperSignatureId
        const documentType = this.formEnvelope.requests[index].DocumentType
        let isPaperSign = false

        this.papers.forEach((paper: any) => {
          if (paper.id === checkPaper) isPaperSign = paper.toSign
        })
        if (!isPaperSign) {
          this.formEnvelope.requests[index].isDocCPF = false
          this.formEnvelope.requests[index].isSaveShared = false
          this.formEnvelope.requests[index].isScheduleRecipient = false
          this.formEnvelope.requests[index].isDocRG = false
          this.formEnvelope.requests[index].isDocOther = false
          this.formEnvelope.requests[index].docMask = ''
          this.formEnvelope.requests[index].DocumentType = ''
          this.formEnvelope.requests[index].Document = ''
          this.formEnvelope.requests[index].isPaperSign = false
        } else {
          if (documentType === '') {
            this.formEnvelope.requests[index].DocumentType = 'CPF'
            this.formEnvelope.requests[index].isDocCPF = true
            this.formEnvelope.requests[index].isSaveShared = true
            this.formEnvelope.requests[index].docMask = '###.###.###-##'
          } else {
            if (documentType === 'RG') {
              this.formEnvelope.requests[index].isDocCPF = false
              this.formEnvelope.requests[index].isSaveShared = true
              this.formEnvelope.requests[index].isDocRG = true
              this.formEnvelope.requests[index].isDocOther = false
              this.formEnvelope.requests[index].docMask = ''
            } else if (documentType === 'CPF') {
              this.formEnvelope.requests[index].isDocCPF = true
              this.formEnvelope.requests[index].isSaveShared = true
              this.formEnvelope.requests[index].isDocRG = false
              this.formEnvelope.requests[index].isDocOther = false
              this.formEnvelope.requests[index].docMask = '###.###.###-##'
            } else {
              this.formEnvelope.requests[index].isDocCPF = false
              this.formEnvelope.requests[index].isSaveShared = true
              this.formEnvelope.requests[index].isDocRG = false
              this.formEnvelope.requests[index].isDocOther = true
              this.formEnvelope.requests[index].docMask = ''
            }
          }
          this.formEnvelope.requests[index].isPaperSign = true
        }

        this.sortRequests(this.formEnvelope.requests)
      }
    },
    showViewer () {
      if (this.isViewer) {
        this.isViewer = false
        this.isForm = true
      } else {
        this.isViewer = true
        this.isForm = false
      }
    },
    removeFileSelected (index = '') {
        const cIndex = this.getActualFileIndex(index)
        this.files.forEach((item: any, index: any, object: any) => {
            if (index === cIndex) {
                object.splice(index, 1)
            }
        })
        this.formEnvelope.files.forEach((item: any, index: any, object: any) => {
            if (index === cIndex) {
                object.splice(index, 1)
            }
        })
        this.activeTabViewer = this.formEnvelope.files.length.toString()
        if (cIndex === 0) this.formEnvelope.title = null
    },
    changeIWantToSign () {
      if (this.isIWantToSign) {
        if (this.isOrderChecked) {
          this.formEnvelope.requests.push({
            Email: this.userData.email,
            PartName: this.userData.name,
            key: Date.now(),
            Order: this.formEnvelope.requests.length + 1,
            PaperSignatureId: 1,
            isWhatsapp: false,
            Phone: '',
            PhoneCode: '+55',
            PhoneMask: '(##) # ####-####',
            PhoneFlag: 'fi fi-br',
            isDocCPF: false,
            isSaveShared: false,
            isDocRG: false,
            isDocOther: false,
            docMask: '',
            Document: '',
            DocumentType: '',
            iWantToSign: true,
            isPaperSign: true,
            isScheduleRecipient: false,
            fromSchedule: false
          })
          this.formEnvelope.requests = this.sortRequests(this.formEnvelope.requests)
        } else {
          this.formEnvelope.requests = this.sortRequests(this.formEnvelope.requests)
          this.formEnvelope.requests.forEach((el, i) => {
            if (el.Order > 0) el.Order = (i + 2)
          })
          this.formEnvelope.requests.unshift({
            Email: this.userData.email,
            PartName: this.userData.name,
            key: Date.now(),
            Order: 1,
            PaperSignatureId: 1,
            isWhatsapp: false,
            Phone: '',
            PhoneCode: '+55',
            PhoneMask: '(##) # ####-####',
            PhoneFlag: 'fi fi-br',
            isDocCPF: false,
            isSaveShared: false,
            isDocRG: false,
            isDocOther: false,
            docMask: '',
            Document: '',
            DocumentType: '',
            iWantToSign: true,
            isPaperSign: true,
            isScheduleRecipient: false,
            fromSchedule: false
          })
        }
        this.inactiveIWantToSign = true
      }
    },
    addToEnvelope (contactsToEnvelope: any) {
      const countFieldsFiled = this.formEnvelope.requests.filter(
        (x) => ((x.Email === '' || x.Phone === '') &&
          (x.Phone.trim() === '' || x.PartName.trim() === '') &&
          x.Document.trim() === '' && x.PartName === '' &&
          (x.Document === '' || !x.isPaperSign)) || x.iWantToSign === true
      ).length

      contactsToEnvelope.forEach((el: any) => {
        const isInFormEnvelope = this.formEnvelope.requests.filter(
            (x) => x.Document === el.requiredDocument &&
            (x.Email === el.collectSignature || ((x.PhoneCode + ' ' + x.Phone) === el.collectSignature))
        ).length

        if (isInFormEnvelope === 0) {
            let isDocCPF = false
            let isSaveShared = false
            let isDocRG = false
            let isDocOther = false
            let docMask = ''
            let email = ''
            let phone = ''
            let phoneCode = '+55'
            let phoneMask = ''
            let phoneFlag = 'fi fi-br'
            let isWhatsapp = false

            if (el.documentType === 'CPF') {
                isDocCPF = true
                isSaveShared = true
                isDocRG = false
                isDocOther = false
                docMask = '###.###.###-##'
            }
            if (el.documentType === 'RG') {
                isDocCPF = false
                isSaveShared = true
                isDocRG = true
                isDocOther = false
                docMask = ''
            }
            if (el.documentType === 'OTHER' || el.documentType === 'OUTRO') {
                isDocCPF = false
                isSaveShared = true
                isDocRG = false
                isDocOther = true
                docMask = ''
            }

            if (el.signatureType === 'email') {
                isWhatsapp = false
                phone = ''
                email = el.collectSignature
            } else {
                isWhatsapp = true
                email = ''
                const baseCollectSignature = el.collectSignature
                const splitPhone = baseCollectSignature.split('(')
                phoneCode = splitPhone[0].trim()
                phone = '(' + splitPhone[1].trim()
                const country = this.countriesWhatsapp.filter((v: any) => v.ddi === phoneCode)
                phoneMask = country[0].mask
                phoneFlag = country[0].flag
            }

            const newRequest = {
                Email: email,
                PartName: el.name,
                key: Date.now(),
                Order: this.formEnvelope.requests.length + 1,
                PaperSignatureId: 1,
                isWhatsapp: isWhatsapp,
                Phone: phone,
                PhoneCode: phoneCode,
                PhoneMask: phoneMask,
                PhoneFlag: phoneFlag,
                isDocCPF: isDocCPF,
                isSaveShared: isSaveShared,
                isDocRG: isDocRG,
                isDocOther: isDocOther,
                docMask: docMask,
                Document: el.requiredDocument,
                DocumentType: el.documentType,
                iWantToSign: false,
                isPaperSign: true,
                isScheduleRecipient: false,
                fromSchedule: true
            }

            if (countFieldsFiled > 0) {
                this.formEnvelope.requests.unshift(newRequest)
            } else {
                this.formEnvelope.requests.push(newRequest)
            }
        }
      })

      this.openSchedule = false
    },
    capitalizeText (text: any) {
      return text.toLowerCase().replace(/(?:^|\s)\S/g, function (letter: any) {
        return letter.toUpperCase()
      })
    },
    checkZoom () {
      const browserZoomLevel = window.devicePixelRatio - 0.25
      this.isZoomed = (browserZoomLevel > 1)
    }
  }
})
